import React from 'react';
import { Box, Container, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: '#f5f5f5' }}>
      <Container maxWidth="sm">
        <Typography variant="body1" align="center">
          © 2024 Zwy & Willbb. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
