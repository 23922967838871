import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, List, ListItem, ListItemText, Box, Modal } from '@mui/material';
import axios from 'axios';

const MessageBoard = () => {
  const [nickname, setNickname] = useState('');
  const [content, setContent] = useState('');
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get('http://localhost:5000/api/messages').then(response => setMessages(response.data));
  }, []);

  const handleSubmit = async () => {
    const response = await axios.post('http://localhost:5000/api/messages', { nickname, content });
    setMessages([...messages, response.data]);
    setNickname('');
    setContent('');
    setOpen(false);
  };

  return (
    <Container>
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          留言板（建设中）
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          说一句话
        </Button>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" gutterBottom>
              Leave a Message
            </Typography>
            <TextField
              label="Nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Message"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Modal>
        <List sx={{ my: 4 }}>
          {messages.map((message) => (
            <ListItem key={message._id}>
              <ListItemText primary={message.nickname} secondary={message.content} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default MessageBoard;

