import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Grid, Box, Modal } from '@mui/material';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';

const PhotoWall = () => {
  const [password, setPassword] = useState('');
  const [file, setFile] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios.get('http://localhost:5000/api/photos').then(response => setPhotos(response.data));
  }, []);

  const handleDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('photo', file);

    const response = await axios.post('http://localhost:5000/api/photos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    setPhotos([...photos, response.data]);
    setPassword('');
    setFile(null);
    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: handleDrop });

  return (
    <Container>
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          照片墙
        </Typography>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          上传一张照片
        </Button>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
            <Typography variant="h6" gutterBottom>
              上传
            </Typography>
            <TextField
              label="暗号"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
            />
            <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}>
              <input {...getInputProps()} />
              {file ? (
                <Typography>{file.name}</Typography>
              ) : (
                <Typography>Drag & drop a photo here, or click to select a photo</Typography>
              )}
            </div>
            <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
              提交
            </Button>
          </Box>
        </Modal>
        <Grid container spacing={2} sx={{ my: 4 }}>
          {photos.map((photo) => (
            <Grid item xs={12} sm={6} md={4} key={photo._id}>
              <img src={photo.url} alt="上传的照片" style={{ width: '100%' }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default PhotoWall;
