import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import MessageBoard from './components/MessageBoard';
import PhotoWall from './components/PhotoWall';
import Footer from './components/Footer';
import { Box } from '@mui/material';

const App = () => {
  return (
    <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/message-board" element={<MessageBoard />} />
          <Route path="/photo-wall" element={<PhotoWall />} />
        </Routes>
        <Footer />
      </Box>
    </Router>
  );
};

export default App;