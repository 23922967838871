import React from 'react';
import { Container, Typography, Box, Card, CardMedia, CardContent, Link } from '@mui/material';

const HomePage = () => {
  return (
    <Container>
      <Box sx={{ textAlign: 'center', my: 4 }}>
      <Box sx={{ position: 'absolute', top: 0, left: 0, p: 2, display: 'flex', alignItems: 'center' }}>
          <Link href="https://www.op.gg/summoners/euw/Will%20bb-EUW" target="_blank" rel="noopener" sx={{ display: 'flex', alignItems: 'center' }}>
            <img src="/lol.jpg" alt="Logo" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
            <Typography variant="h6" sx={{ ml: 2, color: 'pink', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
              Best Lux Player Forever
            </Typography>
          </Link>
        </Box>
        <Typography variant="h2" gutterBottom>
          Welcome to <span style={{ color: 'pink' }}>Willbb</span>
        </Typography>
        <Typography variant="h5" paragraph>
          2024 May
        </Typography>
        <Card sx={{ maxWidth: 'auto', mx: 'auto', my: 'auto' }}>
          <CardMedia
            component="img"
            height="1000"
            image="/hezhao.jpg"
            alt="o"
          />
          <CardContent>
            <Typography variant="body1" color="text.secondary">
              芬兰樱花公园
            </Typography>
          </CardContent>
        </Card>
        <Box sx={{ my: 4 }}>
          <Typography variant="h6" gutterBottom>
            主播阴间再见了XD
          </Typography>
          <audio controls autoPlay loop>
            <source src="/seeuagain.mp3" type="audio/mpeg" />
          </audio>
        </Box>
      </Box>
    </Container>
  );
};

export default HomePage;


